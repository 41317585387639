import Vue from 'vue'
import Vuex from 'vuex'
import actions from './store/actions';
import state from './store/state'
import mutations from './store/mutations'

Vue.use(Vuex)

const getters = 
  {
  isLoggedIn: state => !!state.accessToken,
  authStatus: state => state.authStatus,
  axiosError: state => state.axiosError,
  isXRDIAdmin: state => state.isXRDIAdmin,
  deviceSeats: state => state.deviceSeats,
  logging: state => state.logging,
  activeAccID: state => state.activeAccountID,
  permissions: state => state.permissions,
  certTokens: state => state.activeAccountCertTokens,
  hasAgreedToTerms: state => state.terms_agreed
  }

export default new Vuex.Store(
  {
  state,
  mutations,
  actions,
  getters
  })