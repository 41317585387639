import axios from 'axios';

//Just putting this here now so that it's in scope, and doesn't have to be dispatched.
//Make sure that the footer is clean and doesn't do any scripting
let SanatizeFooter = (data) => {
    let html = new DOMParser().parseFromString(data.footer_text, "text/html");
    if (html.scripts.length > 0) {
        data.footer_text = null;
    }
}

export default {
    //Gets whitelabeling based on domain.
    async GetWhiteLabeling(context) {
        let apiCall = "api/v1/whiteLabel";
        let domain = {domain: window.location.hostname};

        try {
            await axios({url: apiCall, data: domain, method: 'POST'}).then(response => {
                SanatizeFooter(response.data);
                context.commit("updateWhiteLabel", response.data);
                context.dispatch('UpdateWhiteLabel');
            });
        } catch (error) {
            console.log(error);
        }
    },

    //Gets whitelabeling based on the account
    async GetAccountWhiteLabel(context) {
        try {
            await context.dispatch('GetAccountWhiteLabelData', context.state.accountID).then(response => {
                SanatizeFooter(response.data);
                context.commit("updateWhiteLabel", response.data);
                context.dispatch('UpdateWhiteLabel');
            });
        } catch (error) {
            console.log(error);
        }
    },

		GetAccountWhiteLabelData(context, accountId) {
			let url = `api/v1/accounts/${accountId}/whiteLabel`;
			return axios.get(url);
		},

		GetDefaultWhiteLabel(context) {
			let url = `api/v1/accounts/${context.state.accountID}/whiteLabel/default`;
			return axios.get(url);
		},

    async UpdateWhiteLabeling(context, data) {
        let apiCall = "api/v1/accounts/" + this.state.activeAccountID + "/whiteLabel";

				return await axios({url: apiCall, data: data, method: 'POST'});

    },

    async GrantWhiteLabeling(context, data) {
        let apiCall = "api/v1/sales/whiteLabel";
				return await axios({url: apiCall, data: data, method: 'POST'}).then(response => {
						//Do nothing here right now . . .
						alert("Successfully enabled account for whitelabeling.");
				});
    },

    async UpdateWhiteLabelingImages(context, data) {
        let apiCall = "api/v1/accounts/" + this.state.accountID + "/whiteLabel/images";
        let instance = axios.create();
        try {
            await instance.post(apiCall, data);
        } catch (error) {
            return (error);
        }
    },

    //Updates the css for the whitelabel. 
    UpdateWhiteLabel(context) {
        //Set the CSS values
        document.querySelector(':root').style.setProperty('--color-sidebar', this.state.whiteLabel.sidebar_background);
        document.querySelector(':root').style.setProperty('--color-sidebar-highlight', this.state.whiteLabel.sidebar_highlight);
        document.querySelector(':root').style.setProperty('--color-sidebar-font', this.state.whiteLabel.sidebar_text_color);
        document.querySelector(':root').style.setProperty('--color-sidehover', this.state.whiteLabel.sidebar_hover);

        document.querySelector(':root').style.setProperty('--color-body', this.state.whiteLabel.body_background);
        document.querySelector(':root').style.setProperty('--color-tab-highlight', this.state.whiteLabel.tab_highlight);

        document.querySelector(':root').style.setProperty('--color-header-footer', this.state.whiteLabel.header_footer_background);
        document.querySelector(':root').style.setProperty('--color-header-footer-text', this.state.whiteLabel.header_footer_text_color);
        document.querySelector(':root').style.setProperty('--color-header-border', this.state.whiteLabel.header_footer_border);
        document.querySelector(':root').style.setProperty('--color-header-footer-hover', this.state.whiteLabel.user_dropdown_highlight);
        document.querySelector(':root').style.setProperty('--color-header-footer-breadcrumb', this.state.whiteLabel.breadcrumb_highlight);

        document.querySelector(':root').style.setProperty('--color-button-submit', this.state.whiteLabel.button_a_background);
        document.querySelector(':root').style.setProperty('--color-button-submit-text', this.state.whiteLabel.button_a_text_color);

        document.querySelector(':root').style.setProperty('--color-button-control', this.state.whiteLabel.button_b_background);
        document.querySelector(':root').style.setProperty('--color-button-control-text', this.state.whiteLabel.button_b_text_color);

        document.querySelector(':root').style.setProperty('--color-button-edit', this.state.whiteLabel.button_c_background);
        document.querySelector(':root').style.setProperty('--color-button-edit-text', this.state.whiteLabel.button_c_text_color);

        document.querySelector(':root').style.setProperty('--color-login-body', this.state.whiteLabel.login_background);

        //Tab stuff
        document.title = context.state.whiteLabel.page_title;

        //Favicon
        if (context.state.whiteLabel.fav_icon) {
            let url;
            if (window.location.hostname == 'localhost') {
                url = process.env.VUE_APP_DEV_BACKEND_BASE_URL + "/api/v1/whiteLabel/" + context.state.whiteLabel.fav_icon;
            } else if (context.state.whiteLabel.domain && !window.location.toString().includes("controlbyweb.cloud")) {
                url = "https://api." + context.state.whiteLabel.domain.replace('www.', '') + "/api/v1/whiteLabel/" + context.state.whiteLabel.fav_icon;
            } else if (context.state.whiteLabel.subdomain) {
                let subDomain = context.state.whiteLabel.subdomain.substr(0, context.state.whiteLabel.subdomain.indexOf("."));
                url = "https://" + subDomain + ".api.controlbyweb.cloud/api/v1/whiteLabel/" + context.state.whiteLabel.fav_icon;
            }
            document.getElementById("favicon").href = url;
        }
    },
}
