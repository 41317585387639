import axios from 'axios';
import state from './state';

import whitelabeling from './whitelabeling/whitelabeling'

// call this function on a option list of permissions to make them all disabled
// this permission list can then be used to display a permissions list that is disabled (viewable only)
function disablePermissions(permissions) {
    var i;
    for (i = 0; i < permissions.length; i++) {
        disablePermissionGroup(permissions[i]);
    }
}

// helper function that takes all the possible permissions object and adds the 'isDisabled: true' field to each
// option making it disabled.  we'll use this function to generate the possible permissions disabled object
// that we will use for users that can view permissions but not edit them 
function disablePermissionGroup(currentNode) {
    var i, currentChild, result;

    currentNode.isDisabled = true;

    if (currentNode.children !== undefined) {
        // Use a for loop instead of forEach to avoid nested functions
        // Otherwise "return" will not work properly
        for (i = 0; i < currentNode.children.length; i += 1) {
            currentChild = currentNode.children[i];

            // Search in the current child
            result = disablePermissionGroup(currentChild);

            // Return the result if the node has been found
            if (result !== false) {
                return result;
            }
        }
    }

    // The node has not been found, and we have no more options
    return false;
}

export default {
    GetWhiteLabeling: whitelabeling.GetWhiteLabeling,
    UpdateWhiteLabeling: whitelabeling.UpdateWhiteLabeling,
    UpdateWhiteLabelingImages: whitelabeling.UpdateWhiteLabelingImages,
    UpdateWhiteLabel: whitelabeling.UpdateWhiteLabel,
    GrantWhiteLabeling: whitelabeling.GrantWhiteLabeling,
    GetAccountWhiteLabel: whitelabeling.GetAccountWhiteLabel,
		GetDefaultWhiteLabel: whitelabeling.GetDefaultWhiteLabel,
    GetAccountWhiteLabelData: whitelabeling.GetAccountWhiteLabelData,

    getAccountInfo({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: '/api/v1/accounts/self', method: 'GET'}).then(response => {
                // get the other profile information
                localStorage.setItem('accountID', response.data.account_id)
                localStorage.setItem('username', response.data.username)
                localStorage.setItem('firstName', response.data.first_name)
                localStorage.setItem('lastName', response.data.last_name)
                localStorage.setItem('deviceSeats', response.data.device_seats)
                localStorage.setItem('accountName', response.data.account_name)
                if (typeof response.data.isXRDIAdmin === 'undefined') {
                    localStorage.setItem('isXRDIAdmin', 0)
                } else {
                    localStorage.setItem('isXRDIAdmin', response.data.isXRDIAdmin)
                }

                localStorage.setItem('logging', response.data.logging)

                // set the activeAccountID to the accountID to begin with
                localStorage.setItem('activeAccountID', response.data.account_id)
                localStorage.setItem('activeAccountName', response.data.account_name)

                localStorage.setItem('userID', response.data.user_id)

                //remove entirely the permissions that are not currently in use
                resp.data.all_permissions[0].children.splice(6, 1);
                resp.data.all_permissions[0].children.splice(2, 1);

                localStorage.setItem('allPermissions', response.data.all_permissions);

                // create and store a version of allPermissions where they are all disabled
                debugger;
                disablePermissions(response.data.all_permissions);

                localStorage.setItem('allPermissionsDisabled', response.data.all_permissions);

                localStorage.setItem('permissions', response.data.permissions);

                localStorage.setItem('terms_agreed', response.data.terms_agreed);

                commit('syncAccountStateToLocalStorage')

                resolve(response)
            })
                .catch(err => {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('accountID')
                    localStorage.removeItem('username')
                    localStorage.removeItem('firstName')
                    localStorage.removeItem('lastName')
                    localStorage.removeItem('deviceSeats')
                    localStorage.removeItem('accountName')
                    localStorage.removeItem('isXRDIAdmin')
                    localStorage.removeItem('logging')
                    localStorage.removeItem('activeAccountID')
                    localStorage.removeItem('activeAccountName')
                    localStorage.removeItem('userID')
                    localStorage.removeItem('allPermissions')
                    localStorage.removeItem('allPermissionsDisabled')
                    localStorage.removeItem('permissions')
                    localStorage.removeItem('terms_agreed')

                    window.location.replace("/pages/login")

                    commit('authError')
                    reject(err)
                })
        })
    },

    login({commit}, userCredentials) {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accountID')
            localStorage.removeItem('username')
            localStorage.removeItem('firstName')
            localStorage.removeItem('lastName')
            localStorage.removeItem('deviceSeats')
            localStorage.removeItem('accountName')
            localStorage.removeItem('isXRDIAdmin')
            localStorage.removeItem('logging')
            localStorage.removeItem('activeAccountID')
            localStorage.removeItem('activeAccountName')
            localStorage.removeItem('userID')
            localStorage.removeItem('allPermissions');
            localStorage.removeItem('allPermissionsDisabled');
            localStorage.removeItem('permissions');
            localStorage.removeItem('terms_agreed');

            commit('authRequest')
            axios({url: '/api/v1/auth/token', data: userCredentials, method: 'POST'}).then(resp => {
                // the most important things to get are the access token and refresh token
                const accessToken = resp.data.access_token
                const refreshToken = resp.data.refresh_token
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)

                // Add a default authorization header to axios with the access token we just received
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
                commit('authSuccess', accessToken, refreshToken)

                // we already have the account info now, it comes with the reponse for the accessToken
                localStorage.setItem('accountID', resp.data.account_id)
                localStorage.setItem('username', resp.data.username)
                localStorage.setItem('firstName', resp.data.first_name)
                localStorage.setItem('lastName', resp.data.last_name)
                localStorage.setItem('deviceSeats', resp.data.device_seats)
                localStorage.setItem('accountName', resp.data.account_name)

                if (typeof resp.data.isXRDIAdmin === 'undefined') {
                    localStorage.setItem('isXRDIAdmin', 0)
                } else {
                    localStorage.setItem('isXRDIAdmin', resp.data.isXRDIAdmin)
                }

                if (typeof resp.data.white_label === 'undefined') {
                    localStorage.setItem('white_label', 0);
                } else {
                    localStorage.setItem('white_label', 1);
                }

                localStorage.setItem('logging', resp.data.logging)

                // set the activeAccountID to the accountID to begin with
                localStorage.setItem('activeAccountID', resp.data.account_id)
                localStorage.setItem('activeAccountName', resp.data.account_name)

                localStorage.setItem('userID', resp.data.user_id)

                //remove entirely the permissions that are not currently in use
                resp.data.all_permissions[0].children.splice(6, 1);
                resp.data.all_permissions[0].children.splice(2, 1);

                localStorage.setItem('allPermissions', JSON.stringify(resp.data.all_permissions));
                // create and store a version of allPermissions where they are all disabled        
                disablePermissions(resp.data.all_permissions);
                localStorage.setItem('allPermissionsDisabled', JSON.stringify(resp.data.all_permissions));
                localStorage.setItem('permissions', JSON.stringify(resp.data.permissions));

                localStorage.setItem('terms_agreed', resp.data.terms_agreed);

                commit('syncAccountStateToLocalStorage')

                resolve(resp)
            }).catch(err => {
                commit('authError'), reject(err)
            })
        })
    },

    logout({commit}) {
        return new Promise((resolve) => {
            commit('logout')
            //TODO: just use localStorage.clear()
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('accountID')
            localStorage.removeItem('username')
            localStorage.removeItem('firstName')
            localStorage.removeItem('lastName')
            localStorage.removeItem('deviceSeats')
            localStorage.removeItem('accountName')
            localStorage.removeItem('isXRDIAdmin')
            localStorage.removeItem('logging')
            localStorage.removeItem('activeAccountID')
            localStorage.removeItem('activeAccountName')
            localStorage.removeItem('userID')
            localStorage.removeItem('allPermissions');
            localStorage.removeItem('allPermissionsDisabled');
            localStorage.removeItem('permissions');
            localStorage.removeItem('terms_agreed');

            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    },

    async UpdateAccountData(context) {
        await context.dispatch('GetProjectTree');
        await context.dispatch('GetProjectList');
    },

    //Gets an updated version of the project tree
    async GetProjectTree(context) {
        //Try to not let this be called super frequently
        if (this.accountTree != null) {
            if (Date() - this.accountTree[0].time > 1000) {
                return;
            }
        }

        await axios({url: 'api/v1/accounts/' + this.state.accountID + '/descendants', method: 'GET'}).then(response => {
            response.data[0].time = Date();
            context.commit('setAccountTree', response.data);
        });
    },

    GetProjectList(context) {
        axios({url: 'api/v1/accounts/' + this.state.activeAccountID + '/allChildren', method: 'GET'}).then(response => {
            var accountList = []
            for (var i = 0; i < response.data.length; i++) {
                if (this.state.activeAccountID != response.data[i].id) {
                    accountList.push(response.data[i])
                }
            }
            context.commit('setAccountList', accountList);
        });
    },

    async GetDevices(context) {
        await axios({url: 'api/v1/accounts/' + this.state.activeAccountID + '/devices', method: 'GET'}).then((response) => {
            context.commit('setActiveDevices', response.data);
        });
    },

		GetDeviceState(context, deviceId) {
			const url = `api/v1/accounts/${context.state.activeAccountID}/devices/${deviceId}/state.json`;
			return axios.get(url, { params: { time: Date.now() } });
		},

		TriggerCellDeviceNetworkDetach(context, deviceId) {
			const url = `api/v1/airvantage/networkdetach/${deviceId}`;
			return axios.post(url);
		},

    GetCellDevices(context) {
        axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/devices/cell',
            method: 'GET'
        }).then((response) => {
            context.commit('setCellDevices', response.data)
        });
    },

    DeleteDevice(context, item) {
        axios({
            url: 'api/v1/accounts/' + item.account_id + '/devices/' + item.device_id,
            method: 'DELETE'
        }).then((response) => {
            if (response.status === 200) {
                context.commit('removeDeletedDevice', item);
                context.dispatch('GetDevices');
                context.dispatch('getThisAccountSeatInfo');
                return (true);
            }
        });
        return (false);
    },

    async GetDeviceData(context, deviceInfo) {
        var deviceData;
        var DATS;

        await axios({
            url: "api/v1/accounts/" + deviceInfo.account_id + "/devices/" + deviceInfo.device_id,
            method: 'GET'
        }).then((response) => {
            deviceData = response.data;
        });

        await axios({
            url: "api/v1/accounts/" + deviceInfo.account_id + "/devices/" + deviceInfo.device_id + "/DAT",
            method: 'GET'
        }).then((response) => {
            DATS = response.data;
        });

        context.commit('setDeviceInfo', {deviceInfo: deviceData, DATS: DATS});
    },

    UpdateDATs(context) {
        axios({
            url: "api/v1/accounts/" + state.deviceData.deviceInfo.account_id + "/devices/" + state.deviceData.deviceInfo.device_id + "/DAT",
            method: 'GET'
        }).then((response) => {
            context.commit('updateDATs', response.data);
        });
    },

    getAccountSeatInfo(context, accountID) {
        axios({url: 'api/v1/accounts/' + accountID + '/devices/info', method: 'GET'}).then((response) => {
            context.commit('setAccountSeatInfo', response.data);
        });
    },

    getThisAccountSeatInfo(context) {
        context.dispatch('getAccountSeatInfo', this.state.activeAccountID);
    },

    ReturnDeviceInfo(context, deviceID) {
        return axios({
            url: "api/v1/accounts/" + this.state.activeAccountID + "/devices/" + deviceID,
            method: 'GET'
        });
    },

    //Reports and Graphs

    GetReports(context, type) {
        axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/reports',
            params: {report_type: type.toUpperCase()},
            method: 'GET'
        }).then((response) => {
            context.commit('set' + type + 's', response.data);
        });
    },

    GetReportInfo(context, payload) {
        axios({
            url: 'api/v1/accounts/' + payload.account_id + '/reports/' + payload.id,
            method: 'GET'
        }).then((response) => {
            let reportingInfo = response.data;
            if (reportingInfo !== null && reportingInfo !== undefined) {
                context.commit('setReportingInfo', reportingInfo)
            }
        });
    },

    // I don't know if there is a good way to combine this and the one above, but I need the report in the state for
    // all the edit and view stuff, but I need it returned for the dashboard. I didn't want to just have a return and
    // copy the .then from above onto all the calls that need it saved in state
    ReturnReportInfo(context, reportID) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/reports/' + reportID,
            method: 'GET'
        });
        //     .then((response) => {
        //     let reportingInfo = response.data;
        //     context.commit('setReportingInfo', reportingInfo)
        // });
    },

    AddReport(context, payload) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/reports',
            data: payload,
            method: 'POST'
        });
    },

    UpdateReport(context, payload) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/reports/' + payload.id,
            data: payload,
            method: 'PUT'
        });
    },

    DeleteReport(context, payload) {
        axios({
            url: 'api/v1/accounts/' + payload.report.account_id + '/reports/' + payload.report.id,
            method: 'DELETE'
        }).then((response) => {
            if (response.status === 200) {
                context.commit('removeDeleted' + payload.type, payload.report);
                context.dispatch('GetReports', payload.type);
            }
        });
    },

    GetDeviceEndpoints(context) {
        axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/ioendpoints',
            method: 'GET'
        }).then((response) => {
            context.commit("setDeviceEndpoints", response.data);
        });
    },

    UpdateDeviceEndpoints(context, device_id) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/ioendpoints/info/' + device_id,
            method: 'PUT'
        })
    },

    GetDeviceEndpointLogData(context, io_endpoint_ids) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/ioendpoints/log',
            // url: 'api/v1/accounts/'+this.state.activeAccountID+'/devices/615864215/log', //gets log for a whole device as a test
            params: io_endpoint_ids,
            method: 'GET'
        });
    },

    // Dashboard

    GetPanels(context) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/panels',
            method: 'GET'
        });
    },

    UpdatePanels(context, panels) {
        return axios({
            url: 'api/v1/accounts/' + this.state.activeAccountID + '/panels',
            data: {panels: panels},
            method: 'PUT'
        });
    },

		//Admin
		
		async GetDeviceSeats(context) {
			const result = await axios.get(`api/v1/accounts/${this.state.activeAccountID}/deviceSeats`);
      const structured = result.data.map(seat => {
        return {
          id: seat.id,
          log_retention: seat.log_retention,
          account_id: seat.account_id,
          expire: (seat.expire) ? seat.expire.split(" ")[0] : "",
          do_not_renew: seat.do_not_renew,
          device_id: (seat.device) ? seat.device.device_id : "",
          device_name: (seat.device) ? seat.device.name : "",
          iccid: (seat.device && seat.device.iccid) ? seat.device.iccid: "",
          serial_number: (seat.device) ? seat.device.serial_number : ""
        }
      });
			context.commit('setActiveDeviceSeats', structured);
		},
		async UpdateDeviceSeat(context, deviceSeat) {
      try {
			  await axios.patch(`api/v1/accounts/${this.state.activeAccountID}/deviceSeats/${deviceSeat.id}`, { do_not_renew: !deviceSeat.do_not_renew });
        return true;
      }
      catch(err) {
        alert(`Error occured while updating device seat: ${err.message}`);
        return false;
      }
		},
		async GetSIMCardSubscriptions(context) {
			const result = await axios.get(`api/v1/accounts/${this.state.activeAccountID}/SIMcards`);
			context.commit('setSIMCardSubscriptions', result.data);
		},
		async UpdateSIMCard(context, simCard) {
      try {
			  await axios.patch(`api/v1/accounts/${this.state.activeAccountID}/SIMcards/${simCard.id}`, { do_not_renew: !simCard.do_not_renew });
        return true;
      }
      catch(err) {
        alert(`Error occurred while updating SIM card: ${err.message}`);
        return false;
      }
		},
		async GetBlockedDevices(context) {
			const result = await axios.get(`api/v1/admin/devices/blocked`);
			context.commit('setBlockedDevices', result.data);
		},
		async DeleteBlockedDevice(context, serialNumber) {
			const result = await axios.delete(`api/v1/admin/devices/blocked/${serialNumber}`);
		
			if(result.status == 200) {
				const newBlockedDevices = context.state.admin.blockedDevices.filter(ele => ele.serial_number != serialNumber);
				context.commit('setBlockedDevices', newBlockedDevices);
			}

			return result;
		},
		async GetBlockedDevice(context, serialNumber) {
			try {
				const result = await axios.get(`api/v1/admin/devices/blocked/${serialNumber}`);
				if(result.status != 200) {
					alert(result.data);
					return null;
				}

				return result.data;
			}
			catch(err) {
				alert(err);
				return null;
			}
		},
		async BlockDevice(context, params) {
			const result = await axios.post(`api/v1/admin/devices/blocked`, params);

			const blockedDevice = await context.dispatch('GetBlockedDevice', params.serial_number);
			if(blockedDevice != null) {
				context.state.admin.blockedDevices.push(blockedDevice);
			}

			return result;
		},
    async CreateDeviceSeatToken(context, params) {
      try {
        const res = await axios.post(`api/v1/sales/deviceSeatTokens/${params.seats}`, { plan_id: params.plan });
        return res.data;
      }
      catch(err) {
        alert (`Error occurred while creating the device seat token: ${err.message}`);
      }
    },
    async CreateDataPlanToken(context, plan) {
      try {
        const res = await axios.post(`api/v1/sales/dataPlanTokens/${plan}`);
        return res.data;
      }
      catch(err) {
        alert(`Error occurred while creating the data plan token: ${err.message}`);
      }
    },
		async GraftAccount(context, params) {
			try {
				const res = await axios.patch(`api/v1/admin/accounts/${params.accountId}/graftAccount/${params.newParentAccountId}`);
				return res.data;
			}
			catch(err) {
				alert(`Error occurred while grafting account: ${err}`);
			}
		},
		async PerformDeviceAction(context, params) {
			try {
				const res = await axios.patch('api/v1/admin/devices', params);
				return res;
			}
			catch(err) {
				alert(`Error occurred while performing ${params.action} action: ${err}`);
			}
		},
		async PerformWhoHasAction(context, params) {
			try {
				const res = await axios.get(`api/v1/admin/accounts/${params.accountId}/devices/${params.deviceId}/whoHas`);
				return res;
			}	
			catch(err) {
				alert(`Error occurred while performing whohas action: ${err}`);
			}
		},
		async PerformCellDeviceAction(context, params) {
			try {
				const res = await axios.patch('api/v1/admin/devices/cell', params);
				return res;
			}
			catch(err) {
				alert(`Error occurred while performing ${params.action} action: ${err}`);
			}
		},
		GetProductList(context) {
			const url = `api/v1/admin/products`;
			return axios.get(url).then(res => {
				context.commit('setProductList', res.data);
			}).catch(err => {
				alert(`Error occurred when getting product list: ${err}`);
			});
		},
		GetSIMCards(context) {
			const url = 'api/v1/admin/SIMcards';
			return axios.get(url).then(res => {
				context.commit('setSIMCards', res.data);
			}).catch(err => {
				alert(`Error occurred when fetching SIM cards: ${err}`);
			});	
		},
		CreateNewSIMCard(context, simCard) {
			const url = `api/v1/sales/SIMcards`;
			return axios.post(url, simCard).then(res => {
				return context.dispatch('GetSIMCards');
			}).catch(err => {
				alert(`Error occurred when creating SIM card: ${err}`);
			});
		},
		GraftAccount(context, { accountId, newParentId }) {
			const url = `api/v1/admin/accounts/${accountId}/graftAccount/${newParentId}`;
			return axios.patch(url).catch(err => { alert(`Error occurred when grafting account: ${err}`); });
		},
		ResendVerificationEmail(context, username) {
			const url = `api/v1/email/resend/${username}`;
			return axios.post(url);
		}
  }
