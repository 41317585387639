export default {
    sidebarShow: 'responsive',
    sidebarMinimize: false,

    // for authentication
    authStatus: '',
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',

    // logged in user info
    //Identification
    userID: localStorage.getItem('userID') || 0,
    username: localStorage.getItem('username') || '',
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
    //Permissions
    isXRDIAdmin: localStorage.getItem('isXRDIAdmin') || 0,
    white_label: localStorage.getItem('white_label') || 0,
    permissions: localStorage.getItem('permissions') || 0,
    terms_agreed: localStorage.getItem('terms_agreed') || 0,
    //Current Account Details
    activeAccountID: localStorage.getItem('activeAccountID') || 0,
    activeAccountName: localStorage.getItem('activeAccountName') || 0,
    //Root Account details
    accountID: localStorage.getItem('accountID') || 0,
    accountName: localStorage.getItem('accountName') || '',
    deviceSeats: localStorage.getItem('deviceSeats') || 0,
    accountTree: null,
    accountList: null,
    updatedAccountInfo: false,
    //TODO: Not entirely sure where this one goes
    logging: localStorage.getItem('logging') || 0,

    activeAccountCertTokens: [],
    //This is used to populate the device table
    activeAccountDevices: [],

    //Info on seat types - don't depend on this being the same as the current account
    seatInfo: {},

    //Info on the last called device
    deviceData: {deviceInfo: Array, DATS: Array},
    cellDeviceData: {},

    //Reports and Graphs info
    reports: [],
    graphs: [],
    deltas: [],
    reportingInfo: JSON.parse(localStorage.getItem('reportingInfo')) || {
        report_attributes: [],
        report_io_endpoints: [],
        report_formulas: [],
    },
    reportingType: localStorage.getItem('reportingType') || 'Report',
    // This is the list of io_endpoints for the whole account that populate the reporting/components/EndpointTable
    deviceEndpoints: [],

    //Dashboard
    panels: [],

		//Admin
		admin: {},

    //White Labeling Information
    whiteLabel:
        {
            account_id: 1,
            domain: "localhost",
            login_logo: "",
            sidebar_logo: "",
            header_logo: "",
            sidebar_background: "#014e7d",
            sidebar_highlight: "#013e68",
            sidebar_hover: "#526688",
            sidebar_text_color: "#ffffff",
            body_background: "#ffffff",
            login_background: "#ebedef",
            tab_highlight: "#449ac1",
            header_footer_background: "#ffffff",
            header_footer_border: "#d8dbe0",
            header_footer_text_color: "#000000",
            breadcrumb_highlight: '#321fdb',
            user_dropdown_highlight: "#eeeeee",
            button_a_background: "#05a215",
            button_a_text_color: "#fcfcfc",
            button_b_background: "#ebedef",
            button_b_text_color: "#121212",
            button_c_background: "#ebedef",
            button_c_text_color: "#121212",
            footer_links: [],
            footer_text: '',
            page_title: 'ControlByWeb.Cloud',
        },
    usedDomains: [],
    updateUsedSubDomains: [],
    axiosError: false
}
