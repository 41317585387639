//Error Pages
const Page404 = () => import('@/views/pages/errorpages/Page404')
const Page500 = () => import('@/views/pages/errorpages/Page500')

//Login Pages
const Login = () => import('@/views/pages/login/Login')
const ForgotPassword = () => import('@/views/pages/login/ForgotPassword')
const SignUp = () => import('@/views/pages/login/SignUp')
const CheckEmail = () => import('@/views/pages/login/CheckEmail')
const ResetPasswordForm = () => import('@/views/pages/login/ResetPasswordForm')

export default 
    {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: 
        {
        render(c) { return c('router-view') }
        },
    children: [
        {
        path: '404',
        name: 'Page404',
        component: Page404
        },
        {
        path: '500',
        name: 'Page500',
        component: Page500
        },
        {
        path: 'login',
        name: 'Login',
        component: Login
        },
        // The following two routes facilitate the password reset
        {
        path: 'reset-password',
        name: 'reset-password',
        component: ForgotPassword,
        meta: 
            {
            requiresAuth: false
            }
        },
        {
        path: 'sign-up',
        name: 'sign-up',
        component: SignUp,
        meta: 
            {
            requiresAuth: false
            }
        },
        {
        path: 'check-email',
        name: 'check-email',
        component: CheckEmail,
        meta: 
            {
            requiresAuth: false
            }
        },
        {
        path: 'reset-password/:token',
        name: 'reset-password-form',
        component: ResetPasswordForm,
        meta:
            {
            requiresAuth: false
            }
        }]
    }