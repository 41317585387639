import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
} from '@coreui/icons'

import {
    x600Icon
} from "./x600Icon";

import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
} from '@coreui/icons'
import {
    cilArrowRight,
    cilArrowLeft,
    cilArrowTop,
    cilArrowBottom,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilCheckAlt,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilGraph,
    cilSitemap,
    cilChevronCircleDownAlt,
    cilChevronCircleUpAlt,
    cilChevronRight,
    cilXCircle,
    cilTrash,
    cilPlus,
    cilMinus,
    cilCircle,
    cilCaretBottom,
    cilColorPalette,
    cilMenu,
    cilReload,
    cilArrowThickToBottom,
    cilMonitor,
    cilBeaker,
} from '@coreui/icons'

export const iconsSet = Object.assign(
    {},
    {
        cilArrowRight,
        cilArrowLeft,
        cilArrowTop,
        cilArrowBottom,
        cilBan,
        cilBasket,
        cilBell,
        cilCalculator,
        cilCalendar,
        cilCloudDownload,
        cilChartPie,
        cilCheck,
        cilCheckAlt,
        cilChevronBottom,
        cilChevronTop,
        cilCheckCircle,
        cilCommentSquare,
        cilCursor,
        cilDrop,
        cilDollar,
        cilEnvelopeClosed,
        cilEnvelopeOpen,
        cilEuro,
        cilGlobeAlt,
        cilGrid,
        cilFile,
        cilJustifyCenter,
        cilLaptop,
        cilLayers,
        cilLightbulb,
        cilList,
        cilLocationPin,
        cilLockLocked,
        cilMagnifyingGlass,
        cilMoon,
        cilOptions,
        cilPencil,
        cilPeople,
        cilPuzzle,
        cilSettings,
        cilShieldAlt,
        cilSpeech,
        cilSpeedometer,
        cilStar,
        cilTask,
        cilUser,
        cilUserFemale,
        cilUserFollow,
        cilGraph,
        cilSitemap,
        cilChevronCircleDownAlt,
        cilChevronCircleUpAlt,
        cilChevronRight,
        cilXCircle,
        cilTrash,
        cilPlus,
        cilMinus,
        cilCircle,
        cilCaretBottom,
        cilColorPalette,
        cilMenu,
        cilReload,
        cilArrowThickToBottom,
        cilMonitor,
        cilBeaker,
    },
    {
        cifUs,
        cifBr,
        cifIn,
        cifFr,
        cifEs,
        cifPl
    },
    {
        cibFacebook,
        cibTwitter,
        cibLinkedin,
        cibFlickr,
        cibTumblr,
        cibXing,
        cibGithub,
        cibStackoverflow,
        cibYoutube,
        cibDribbble,
        cibInstagram,
        cibPinterest,
        cibVk,
        cibYahoo,
        cibBehance,
        cibReddit,
        cibVimeo,
        cibCcMastercard,
        cibCcVisa,
        cibStripe,
        cibPaypal,
        cibGooglePay,
        cibCcAmex,
        x600Icon,
    }
)
