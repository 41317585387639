import Vue from 'vue'
import Router from 'vue-router'

import rootRoutes from '@/router/routes/root.js';
import adminRoutes from '@/router/routes/admin.js';
import pagesRoutes from '@/router/routes/pages.js';

//Error Pages
const Page404 = () => import('@/views/pages/errorpages/Page404')

Vue.use(Router)

const router = new Router(
  {
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
  })

function configRoutes() 
  {
  return [
    rootRoutes,
    adminRoutes,
    pagesRoutes,
    { path: '*', component: Page404 }]
  }

  export default router;