<template>
  <div v-if="loaded">
    <router-view />
  </div>
  <div style="margin-top:10em;" v-else>
    <Loading :message='"Loading . . ."' />
  </div>
</template>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

<script>
import store from './store'
import router from './router'
import permissions from '@/permissions'
import Loading from "@/widgets/animations/ani_Loading";
 
export default 
  {
  name: 'App',
  store: store,
  components:
    {
    Loading,
    },
  data()
    {
    return(
      {
      loaded : false,
      });
    },
  created() 
    {
    //Load in whitelabel, once loaded, load the page
    this.$store.dispatch("GetWhiteLabeling").then(() =>
      {
      this.loaded = true;
      });
    // we use this route guard to check if the route requires authentication if so, we check before allowing them to proceed
    // this also displays the progress bar
    this.$router.beforeEach((to, from, next) => 
      {
      // remove progress bar for loading webpages it messes up loading the tables and showing the progress bar
      // maybe we can get this to work later on
      this.showProgressBar()

      if (to.matched.some(record => record.meta.requiresAuth)) 
        {
        if (localStorage.getItem('accessToken') !== null) 
          {
          if(permissions.hasPermissionToViewPage(to.name))
            {
            next();
            return;
            }
          }          
        }
      else 
        {
        next();
        }
      })

    // after we navigate to a new page we need to remote the progress bar
    this.$router.afterEach((to, from) => { this.hideProgressBar() })

    this.$http.interceptors.response.use(function (response) 
      {
      // Any status code that lie within the range of 2xx cause this function to trigger. Do something with response data
      return response;
      }, 
    function (error) 
      {
      // Any status codes that falls outside the range of 2xx cause this function to trigger. Do something with response error
      if (error.response) 
        {
        // the error response doesn't contain a direct status code. we must search for it
        if (error.response.status === 401) 
          {
          store.dispatch('logout').then(() => { router.push('/pages/login') })
          }
        } 
      return Promise.reject(error)
      });
    },
  mounted() 
    {
    this.$Progress.finish()
    },
  }
</script>
