import permissions from '@/permissions';

// Containers
const TheContainer = () => import('@/containers/pagelayout/TheContainer');

//XRDI Admin Pages
const AdminManageAccountTree = () => import('@/views/admin/ManageAccountTree');
const AdminProductsList = () => import('@/views/admin/Products')
const AdminProductEdit = () => import('@/views/admin/ProductEdit')
const AdminProductAdd = () => import('@/views/admin/ProductAdd')
const AdminToolsView = () => import('@/views/admin/subs/Subscriptions');


export default 
    {
    path: '/admin',
    redirect: '/admin/accounts',
    name: 'Admin',
    component: TheContainer,
    beforeEnter: (to, from, next) => {
      if (permissions.hasPermissionToViewPage(to.name)) {
        next();
      }
      else {
        alert("You do not have permission to view this page.");
        next("/");
      }
    },
    children: [
        {
        path: 'products',
        name: 'Products',
        component: AdminProductsList,
        meta: 
            {
            requiresAuth: true
            }
        },
        {
        path: 'productEdit/:id',
        name: 'Product Edit',
        component: AdminProductEdit,
        meta: 
            {
            requiresAuth: true
            }
        },
        {
        path: 'productAdd',
        name: 'Product Add',
        component: AdminProductAdd,
            meta: 
            {
            requiresAuth: true
            }
        },
				{
				path: 'tools',
				name: 'Tools',
				component: AdminToolsView,
					meta: {
						requiresAuth: true
					}
				},
				{
				path: 'manageAccountTree',
				name: 'ManageAccountTree',
				component: AdminManageAccountTree,
				meta: 
					{
					requiresAuth: true
					}
				}
			]
    }
