// This file contains functions for handling permissions in the rest of the site
// If we need to had permission functionality we should try and put function here
// to handle it.
var permissions = 
    {
    // return true if an element in 'arr' is found in 'haystack'
    // otherwise return false
    // both haystack and arr must be arrays
    findOne(haystack, arr) 
        {
        if(arr === null || haystack == null)
            {
            return 0
            }
        return arr.some(function (v)
            {
            if(v == null)
                {
                return false;
                }
            return haystack.indexOf(v) >= 0;
            });
        },

    // This function will return true if the user has permission to go to the requested page
    // The page name is the name: found in the routes in index.js and this function is called
    // in the file store.js before allowing routes through
    hasPermissionToViewPage(name) 
        {
        var permissions = JSON.parse(localStorage.getItem("permissions"));
        const isXRDIAdmin = Boolean(Number(localStorage.getItem("isXRDIAdmin")));
        switch (name) 
            {
            // we only completley block on device or accounts, never users
          case "Devices":
            return this.findOne(["Setup Devices","Control Devices","View Logged Data","Add Devices","Edit Devices","Delete Devices", "Add DAT Urls", "Edit DAT Urls", "Delete DAT Urls"], permissions)
          case "Accounts":
            return this.findOne(["Add Accounts","Edit Accounts","Delete Accounts"], permissions)

          // Admin routes need to check if user is admin
          case "Generate Token":
          case "Subscriptions":
          case "Products":
          case "Product Edit":
          case "Product Add":
            return isXRDIAdmin;
          default:
            return true;
            }
        },

    // This function just takes the name of a permission and return true if the logged in user has the permmision
    // false if not
    hasPermissionTo(names) 
        {
        var permissions = JSON.parse(localStorage.getItem("permissions"))
        if(names.isArray)
            {
            return this.findOne(permissions, names)
            } 
        else 
            {
            var arr = [];
            arr.push(names); 
            return this.findOne(permissions, arr)
            }
        },
    }
    
export default permissions