// Containers
const TheContainer = () => import('@/containers/pagelayout/TheContainer');

// Views
const Summary = () => import('@/views/user/accounts/Summary');

//Devices
const Devices = () => import('@/views/user/devices/Devices');
const DeviceEdit = () => import('@/views/user/devices/DeviceEdit');
const DeviceAdd = () => import('@/views/user/devices/DeviceAdd');

const Logs = () => import('@/views/user/logging/Logs');
const PasswordEdit = () => import('@/views/user/profile/PasswordEdit');
const Tokens = () => import('@/views/user/devices/Tokens');
const TagEdit = () => import('@/views/user/tags/TagEdit');
const TagAdd = () => import('@/views/user/tags/TagAdd');
const DATEdit = () => import('@/views/user/DATEdit');
const DATAdd = () => import('@/views/user/DATAdd');

//Users Pages
const Users = () => import('@/views/user/users/Users');
const UserEdit = () => import('@/views/user/users/UserEdit');
const UserAdd = () => import('@/views/user/users/UserAdd');

//Accounts pages
const AccountEdit = () => import('@/views/user/accounts/AccountEdit');
const AccountAdd = () => import('@/views/user/accounts/AccountAdd');

const RemoteLogServerEdit = () => import('@/views/user/remotelogservers/RemoteLogServerEdit');
const RemoteLogServerAdd = () => import('@/views/user/remotelogservers/RemoteLogServerAdd');
const RegisterCodes = () => import('@/views/user/RegisterCodes');

// White Labeling
const Whitelisting = () => import('@/views/user/Whitelabeling');

// Reporting
const Reporting = () => import('@/views/user/reporting/Reporting');
const ReportingEditSelect = () => import('@/views/user/reporting/ReportingEditSelect');
const ReportingEdit = () => import('@/views/user/reporting/ReportingEdit');
const ReportingAddSelect = () => import('@/views/user/reporting/ReportingAddSelect');
const ReportingAdd = () => import('@/views/user/reporting/ReportingAdd');
const ReportingView = () => import('@/views/user/reporting/ReportingView');

//Dashboards
const Dashboard = () => import('@/views/user/dashboards/Dashboard');

export default {
    path: '/',
    redirect: "summary",
    //redirect: '/dashboard',
    name: 'Home',
    component: TheContainer,
    meta: {
        requiresAuth: true,
    },
    beforeEnter(to, from, next) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (localStorage.getItem('accessToken') !== null) {
                next();
                return;
            }
            next('pages/login')
        } else {
            next();
        }
    },
    children: [
        {
            path: 'summary',
            name: 'Summary',
            component: Summary,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'devices',
            name: 'Devices',
            component: Devices,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'logs',
            name: 'Logs',
            component: Logs,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'deviceEdit/:account_id/:device_id',
            name: 'Device Edit',
            component: DeviceEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'deviceAdd',
            name: 'Add Device',
            component: DeviceAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'passwordEdit',
            name: 'Change Password',
            component: PasswordEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'tokens',
            name: 'Tokens',
            component: Tokens,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'tagEdit/:account_id/:devID/:key',
            name: 'Tag Edit',
            component: TagEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'tagAdd/:account_id',
            name: 'Tag Add',
            component: TagAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'DATEdit/:account_id/:devID/:dat',
            name: 'DAT Edit',
            component: DATEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'DATAdd/:account_id/:devID',
            name: 'DAT Add',
            component: DATAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'users',
            name: 'Users',
            component: Users,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'userEdit/:account_id/:id',
            name: 'User Edit',
            component: UserEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'userAdd',
            name: 'Add User',
            component: UserAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'whitelabeling',
            name: 'White Labeling',
            component: Whitelisting,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'accountEdit/:id',
            name: 'Account Edit',
            component: AccountEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'accountAdd',
            name: 'Add Account',
            component: AccountAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'RemoteLogServerEdit/:accountID/:remoteLogServerID',
            name: 'Remote Log Server Edit',
            component: RemoteLogServerEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'RemoteLogServerAdd',
            name: 'Remote Log Server Add',
            component: RemoteLogServerAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'RegisterCodes',
            name: 'Register Codes',
            component: RegisterCodes,
            meta: {
                requiresAuth: true,
            }
        },
        /* Hidng report endpoints
        {
            path: 'reporting',
            name: 'Reporting',
            component: Reporting,
            meta: {
                requiresAuth: true,
            }
        },
        //TODO: see if there is a clean way to merge the edit and add routes into the same views
        {
            path: 'reportingEditSelect/:account_id/:id',
            name: 'Edit Reporting Select',
            component: ReportingEditSelect,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'reportingEdit/:account_id/:id',
            name: 'Edit Reporting',
            component: ReportingEdit,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'reportingAddSelect',
            name: 'Add Reporting Select',
            component: ReportingAddSelect,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'reportingAdd',
            name: 'Add Reporting',
            component: ReportingAdd,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'reportingView/:account_id/:id',
            name: 'View Reporting',
            component: ReportingView,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: 'dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true,
            }
        }
        */
    ]
}
