import router from '../router'

export default {
    setAccountTree(state, accountTree) {
        state.accountTree = accountTree;
    },

    setAccountList(state, accountList) {
        state.accountList = accountList;
    },

    setActiveAccount(state, node) {
        state.activeAccountID = node.id;
        state.activeAccountName = node.account_name;

        localStorage.setItem('activeAccountID', node.id);
        localStorage.setItem('activeAccountName', node.account_name);

        this.dispatch('GetProjectList');

        //Bandaid on a bigger issue. I've started fixing it, but we need to move on to billing.
        //Eventually I want this gone
        router.go();
    },

    removeDeletedDevice(state, device) {
        for (var i = 0; i < state.activeAccountDevices.length; i++) {
            if (device.device_id == state.activeAccountDevices[i].device_id) {
                state.activeAccountDevices.splice(i, 1)
            }
        }
    },

    setActiveDevices(state, devices) {
        state.activeAccountDevices = devices;
    },

    setAccountSeatInfo(state, seatInfo) {
        state.seatInfo = seatInfo;
    },

    setDeviceInfo(state, deviceInfo) {
        state.deviceData = deviceInfo;
    },

    setCellDevices(state, cellInfo) {
        state.cellDeviceData = cellInfo;
    },

    updateDATs(state, DATS) {
        state.deviceData.DATS = DATS;
    },

    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    //TODO: Get rid of this. It's really bad practice
    set(state, [variable, value]) {
        state[variable] = value
    },
    setAxiosError(state, val) {
        state.axiosError = val;
    },
    // for authentication
    //TODO : Fix this
    authRequest(state, authStatus) {
        state.authStatus = 'loading';
    },
    authSuccess(state, authStatus, accessToken, refreshToken) {
        state.authStatus = 'success'
        state.accessToken = accessToken
        state.refreshToken = refreshToken
    },
    authError(state, authStatus) {
        state.authStatus = 'error'
    },
    logout(state, authStatus) {
        state.authStatus = ''
        state.accessToken = ''
        state.refreshToken = ''
        state.accountID = 0
        state.username = ''
        state.firstName = ''
        state.lastName = ''
        state.deviceSeats = 0
        state.accountName = ''
        state.isXRDIAdmin = 0
        state.logging = 0
        state.activeAccountID = 0
        state.userID = 0
        state.permissions = []
        state.terms_agreed = 0
    },
    syncAccountStateToLocalStorage(state) {
        state.accountID = localStorage.getItem('accountID');
        state.username = localStorage.getItem('username');
        state.firstName = localStorage.getItem('firstName');
        state.lastName = localStorage.getItem('lastName');
        state.deviceSeats = localStorage.getItem('deviceSeats');
        state.accountName = localStorage.getItem('accountName');
        state.isXRDIAdmin = localStorage.getItem('isXRDIAdmin');
        state.logging = localStorage.getItem('logging');
        state.activeAccountID = localStorage.getItem('activeAccountID');
        state.activeAccountName = localStorage.getItem('activeAccountName');
        state.userID = localStorage.getItem('userID');
        state.permissions = localStorage.getItem('permissions');
        state.terms_agreed = localStorage.getItem('terms_agreed');
        state.white_label = localStorage.getItem('white_label');
    },
    updateUsedDomains(state, usedDomains) {
        state.usedDomains = usedDomains;
    },
    updateUsedSubDomains(state, updateUsedSubDomains) {
        state.updateUsedSubDomains = updateUsedSubDomains;
    },
    updateWhiteLabel(state, whiteLabel) {
        whiteLabel.footer_links = JSON.parse(whiteLabel.footer_links)
        state.whiteLabel = whiteLabel;
    },

    // Reports and Graphs

    setReports(state, reports) {
        state.reports = reports;
    },

    setGraphs(state, graphs) {
        state.graphs = graphs;
    },

    setDeltas(state, deltas) {
        state.deltas = deltas
    },

    removeDeletedReport(state, report) {
        for (var i = 0; i < state.reports.length; i++) {
            if (report.id == state.reports[i].id) {
                state.reports.splice(i, 1)
            }
        }
    },

    removeDeletedGraph(state, graph) {
        for (var i = 0; i < state.graphs.length; i++) {
            if (graph.id == state.graphs[i].id) {
                state.graphs.splice(i, 1)
            }
        }
    },

    removeDeletedDelta(state, delta) {
        for (var i = 0; i < state.deltas.length; i++) {
            if (delta.id == state.deltas[i].id) {
                state.deltas.splice(i, 1)
            }
        }
    },

    setReportingInfo(state, reportInfo) {
        state.reportingInfo = reportInfo;
        localStorage.setItem('reportingInfo', JSON.stringify(reportInfo))
    },

    setReportingType(state, type) {
        state.reportingType = type;
        localStorage.setItem('reportingType', type);
    },

    setDeviceEndpoints(state, deviceEndpoints) {
        state.deviceEndpoints = deviceEndpoints;
    },

    addEndpointsToReport(state, endpoints) {
        state.reportingInfo.report_io_endpoints = endpoints
        localStorage.setItem('reportingInfo', JSON.stringify(state.reportingInfo))
    },

    // Dashboard

    setPanels(state, panels) {
        state.panels = panels
    },

		// Admin
		setActiveDeviceSeats(state, deviceSeats) {
			state.admin.deviceSeats = deviceSeats;
			localStorage.setItem('admin', JSON.stringify(state.admin));
		},
		setSIMCardSubscriptions(state, subs) {
			state.admin.simCardSubscriptions = subs;
			localStorage.setItem('admin', JSON.stringify(state.admin));
		},
		setBlockedDevices(state, blockedDevices) {
			state.admin.blockedDevices = blockedDevices;
			localStorage.setItem('admin', JSON.stringify(state.admin));
		},
		setSIMCards(state, simCards) {
			state.admin.simCards = simCards;
			localStorage.setItem('admin', JSON.stringify(state.admin));
		},
		setProductList(state, products) {
			state.admin.products = products;
			localStorage.setItem('admin', JSON.stringify(state.admin));
		}
}
